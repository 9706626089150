/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, memo, useMemo, useEffect } from "react";
import { jsx, Button, Input, Label } from "theme-ui";
import { useTable, useSortBy, useRowSelect } from "react-table";
import axios from "axios";
import WithdrawFlow from "./WithdrawFlow";
import SortUp from "../icons/SortUp";
import SortDown from "../icons/SortDown";
import Heading from "./Heading";
import ArrowLeft from "../icons/ArrowLeft";
import AddToCellar from "../components/AddToCellar";
import Modal from "../components/Modal";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { baseUrl } from "../utils/constants";

export default function Cellar({
  deposits,
  token,
  user,
  isAdmin = false,
  uuid,
  cellarOwner,
  fetchCellar,
}) {
  const [widthdrawalFlow, setWithdrawalFlow] = useState(false);
  const [editFlow, setEditFlow] = useState(false);
  const [editBottleFlow, setEditBottleFlow] = useState(false);
  const [deleteFlow, setDeleteFlow] = useState(false);
  const [search, setSearch] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editingRow, setEditingRow] = useState(false);

  const name =
    user.name && user.name.indexOf(" ") > -1
      ? user.name.split(" ")[0]
      : user.name;

  const greetings = ["Bonjour,", "Welcome back,"];

  const [greeting] = useState(
    greetings[Math.floor(Math.random() * greetings.length)]
  );

  useEffect(() => {
    if (widthdrawalFlow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [widthdrawalFlow]);

  console.log("Cellar", deposits, deposits.length);

  function getSearch(string, deposits) {
    if (string !== "") {
      return deposits.filter((row) => {
        const term = string.toLowerCase();
        return (
          row.bottle.region.toLowerCase().indexOf(term) > -1 ||
          row.bottle.appellation.toLowerCase().indexOf(term) > -1 ||
          row.bottle.domain.toLowerCase().indexOf(term) > -1 ||
          row.bottle.cuvee.toLowerCase().indexOf(term) > -1
        );
      });
    } else {
      return deposits;
    }
  }

  const data = useMemo(() => getSearch(search, deposits), [search, deposits]);

  const handleDownloadClick = (e) => {
    e.preventDefault();
    const button = document.querySelector("#test-table-xls-button");
    if (button) {
      button.click();
    }
  };

  let d = new Date();
  const filename = `planque-export-${d.getDate()}-${
    d.getMonth() + 1
  }-${d.getFullYear()}`;

  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "bottle.colour",
        columnWidth: 70,
        capitalize: true,
      },
      {
        Header: "Country",
        accessor: "bottle.country",
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value}
              {isAdmin && (
                <div
                  className="hideChildren--child"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 20,
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    px: 10,
                    fontWeight: "400",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEditBottleModal(props.row);
                  }}
                >
                  edit
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Region",
        accessor: "bottle.region",
        sortType: "basic",
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value}
              {isAdmin && (
                <div
                  className="hideChildren--child"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 20,
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    px: 10,
                    fontWeight: "400",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEditBottleModal(props.row);
                  }}
                >
                  edit
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Designation",
        accessor: "bottle.appellation",
        sortType: "basic",
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value}
              {isAdmin && (
                <div
                  className="hideChildren--child"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 20,
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    px: 10,
                    fontWeight: "400",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEditBottleModal(props.row);
                  }}
                >
                  edit
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Producer",
        accessor: "bottle.domain",
        sortType: "basic",
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value}
              {isAdmin && (
                <div
                  className="hideChildren--child"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 20,
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    px: 10,
                    fontWeight: "400",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEditBottleModal(props.row);
                  }}
                >
                  edit
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Cuvée",
        accessor: "bottle.cuvee",
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value}
              {isAdmin && (
                <div
                  className="hideChildren--child"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 20,
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    px: 10,
                    fontWeight: "400",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEditBottleModal(props.row);
                  }}
                >
                  edit
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Vintage",
        accessor: "variant.vintage",
        sortType: "basic",
        columnWidth: 100,
        Cell: (props) => {
          const { value } = props.cell;
          return <div>{value === "0" ? "NV" : value}</div>;
        },
      },
      {
        Header: "Size",
        accessor: "variant.size",
        sortType: "basic",
        columnWidth: 100,
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {formatSize(props.cell.value)}
              {isAdmin && (
                <div
                  className="hideChildren--child"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 20,
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    px: 10,
                    fontWeight: "400",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEditModal(props.row);
                  }}
                >
                  edit
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Qty.",
        accessor: "quantity",
        sortType: "basic",
        columnWidth: 80,
        Cell: (props) => {
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {props.cell.value}
              {isAdmin && (
                <div
                  className="hideChildren--child"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 20,
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    px: 10,
                    fontWeight: "400",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEditModal(props.row);
                  }}
                >
                  edit
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Location",
        accessor: "variant.name",
        sortType: "basic",
        columnWidth: 200,
        Cell: (props) => {
          const { value } = props.cell;
          return (
            <div className="hideChildren" sx={{ position: "relative" }}>
              {value ? value : "–"}
              {isAdmin && (
                <div
                  className="hideChildren--child"
                  sx={{
                    position: "absolute",
                    top: "-5px",
                    bottom: "-5px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    borderRadius: 20,
                    backgroundColor: "black",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    px: 10,
                    fontWeight: "400",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    openEditModal(props.row);
                  }}
                >
                  edit
                </div>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      data,
      columns,
      initialState: {
        hiddenColumns: !isAdmin ? ["variant.name"] : [],
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div sx={{ width: 40, variant: "components.flexCenter" }}>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div sx={{ width: 40, variant: "components.flexCenter" }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          columnWidth: 40,
        },
        ...columns,
      ]);
    }
  );

  const selectedRows = Object.keys(selectedRowIds);
  const items = selectedRows.length === 1 ? "item" : "items";

  const closeModal = () => {
    setAddModal(false);
    setEditFlow(false);
    setEditBottleFlow(false);
    setDeleteFlow(false);
  };

  let bottleCount = cellarOwner.bottle_count;
  console.log("deposits", deposits);

  console.log("cellarOwner", cellarOwner);
  console.log("user", user);

  const openEditModal = (props) => {
    setEditingRow(props.original);
    setEditFlow(true);
  };

  const openEditBottleModal = (props) => {
    setEditingRow(props.original);
    setEditBottleFlow(true);
  };

  const cellarAllowance = cellarOwner
    ? cellarOwner.cellar_allowance
    : user.cellar_allowance;

  return (
    <div>
      <div
        sx={{
          py: [10, 30],
          display: "flex",
        }}
      >
        <Modal isOpen={addModal} closeModal={closeModal} heading="Add wine">
          <AddToCellar
            uuid={uuid}
            token={token}
            fetchCellar={fetchCellar}
            closeModal={closeModal}
          />
        </Modal>
        <Modal isOpen={editFlow} closeModal={closeModal} heading="Edit Variant">
          {editingRow && (
            <Edit
              editingRow={editingRow}
              token={token}
              fetchCellar={fetchCellar}
              closeModal={closeModal}
            />
          )}
        </Modal>
        <Modal
          isOpen={editBottleFlow}
          closeModal={closeModal}
          heading="Edit Bottle"
        >
          {editingRow && (
            <EditBottle
              editingRow={editingRow}
              token={token}
              fetchCellar={fetchCellar}
              closeModal={closeModal}
            />
          )}
        </Modal>
        <Modal isOpen={deleteFlow} closeModal={closeModal} heading="Delete">
          <Delete
            selectedFlatRows={selectedFlatRows}
            token={token}
            fetchCellar={fetchCellar}
            closeModal={closeModal}
          />
        </Modal>
        <div
          sx={{
            display: "flex",
            fontSize: [24, 36],
            fontWeight: 300,
            alignItems: "center",
            fontFamily: "heading",
          }}
        >
          {cellarOwner && cellarOwner.name ? (
            <div sx={{ display: "flex", alignItems: "center" }}>
              <a
                href="/admin"
                sx={{
                  display: "flex",
                  fontFamily: "heading",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "100%",
                  fontSize: 20,
                  mr: 20,
                  width: 40,
                  height: 40,
                  "&:hover": {
                    transform: "translateY(-1px)",
                  },
                }}
              >
                <ArrowLeft fill="black" />
              </a>

              <div
                sx={{
                  mr: 30,
                  fontFamily: "heading",
                  display: "flex",
                  alignItems: "center",
                  fontSize: [24, 36],
                  position: "relative",
                  minWidth: 50,
                }}
              >
                <Label sx={{ position: "absolute", fontSize: 10, top: "-4px" }}>
                  {cellarOwner.email}
                </Label>
                <div sx={{ pt: "12px" }}>{cellarOwner.name}</div>
              </div>
            </div>
          ) : (
            <div sx={{ display: "flex" }}>
              <span sx={{ display: ["flex", "none"] }}>Hey,&nbsp;</span>
              <span sx={{ display: ["none", "flex"] }}>{greeting}&nbsp;</span>
              {name}
            </div>
          )}
        </div>
        <div sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
          <div
            sx={{
              mr: 30,
              fontFamily: "heading",
              display: "flex",
              alignItems: "center",
              fontSize: [24, 36],
              position: "relative",
              minWidth: 50,
              color: "black",
            }}
          >
            <Label sx={{ position: "absolute", fontSize: 10, top: "-4px" }}>
              Bottles
            </Label>
            <div sx={{ pt: "12px" }}>
              {bottleCount}
              {cellarAllowance ? `/${cellarAllowance}` : ""}
            </div>
          </div>
          {user.corkage && (
            <div
              sx={{
                mr: 30,
                fontFamily: "heading",
                display: "flex",
                alignItems: "center",
                fontSize: [24, 36],
                position: "relative",
              }}
            >
              <Label sx={{ position: "absolute", fontSize: 10, top: "-4px" }}>
                Corkage
              </Label>
              <div sx={{ pt: "12px" }}>
                {cellarOwner ? cellarOwner.corkage : user.corkage}
              </div>
            </div>
          )}
          <div sx={{ mr: 30 }}>
            <Input
              placeholder="Search..."
              sx={{
                color: "text",
                padding: "14px",
                borderColor: "white",
                boxShadow: "0 4px 16px 0 rgba(33,37,41,0.04)",
                background: "white",
                px: 20,
                py: 10,
                borderRadius: 20,
                fontFamily: "'Mabry Pro', system-ui, sans-serif",
              }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <div>
            <Button
              onClick={handleDownloadClick}
              variant="ghost"
              sx={{
                borderRadius: 100,
                height: 40,
                lineHeight: "40px",
                py: 0,
                minWidth: 190,
              }}
            >
              Download as XLS
            </Button>
            <div sx={{ display: "none" }}>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="planque"
                filename={filename}
                sheet="Planque"
                buttonText="Download as XLS"
              />
            </div>
          </div>
          {isAdmin && (
            <Button
              onClick={() => {
                setAddModal(true);
              }}
              sx={{
                borderRadius: 100,
                height: 40,
                backgroundColor: "black",
                color: "white",
                lineHeight: "40px",
                py: 0,
                minWidth: 150,
                ml: 20,
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
            >
              Add Wine
            </Button>
          )}
        </div>
      </div>

      {selectedRows.length !== 0 && (
        <div
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "pastel",
            zIndex: 100,
            padding: 20,
            display: "flex",
            alignItems: "center",
            // borderTop: "1px solid black"
            boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)",
          }}
        >
          <span sx={{ fontSize: 24 }}>
            {selectedRows.length} selected {items}
          </span>
          <div sx={{ ml: "auto", display: "flex" }}>
            {isAdmin && (
              <div sx={{ display: "flex" }}>
                <div sx={{ mr: 10 }}>
                  <Button
                    sx={{
                      variant: "buttons.ghost",
                      px: 40,
                      borderRadius: 100,
                    }}
                    onClick={(e) => {
                      setDeleteFlow(true);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            )}
            <Button
              sx={{ variant: "components.button", px: 40, borderRadius: 100 }}
              onClick={(e) => {
                e.preventDefault();
                setWithdrawalFlow(true);
              }}
            >
              Request withdrawal
            </Button>
          </div>
        </div>
      )}

      {data.length === 0 && search !== "" ? (
        <div
          sx={{
            px: [10, 50],
            height: "40vh",
            pb: [10, 50],
            variant: "components.flexCenter",
          }}
        >
          <Heading>0 results for &apos;{search}&apos;</Heading>
        </div>
      ) : (
        <table
          {...getTableProps()}
          id="planque"
          style={{
            width: "100%",
            fontSize: 16,
            borderCollapse: "collapse",
          }}
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`tr-${i}`}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    sx={{
                      width: column.columnWidth
                        ? `${column.columnWidth}px`
                        : "auto",
                      textTransform: column.capitalize
                        ? "capitalize"
                        : "initial",
                    }}
                  >
                    <div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="table-header"
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortDown />
                        ) : (
                          <SortUp />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} sx={{ textAlign: "center" }}>
            {rows.map((row, i) => {
              prepareRow(row);
              const isSelected = selectedRowIds[row.id];

              return (
                <MemoizedRow
                  row={row}
                  isSelected={isSelected}
                  key={`row-${i}`}
                />
              );
            })}
          </tbody>
        </table>
      )}

      {widthdrawalFlow && (
        <ModalScreen>
          <WithdrawFlow
            setWithdrawalFlow={setWithdrawalFlow}
            selectedFlatRows={selectedFlatRows}
            token={token}
            cellarOwner={cellarOwner}
            isAdmin={isAdmin}
          />
        </ModalScreen>
      )}

      <div sx={{ display: "none" }}>
        <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
        <pre>
          <code>
            {JSON.stringify(
              {
                selectedRowIds: selectedRowIds,
                "selectedFlatRows[].original": selectedFlatRows.map(
                  (d) => d.original
                ),
              },
              null,
              2
            )}
          </code>
        </pre>
      </div>
    </div>
  );
}

const MemoizedRow = memo((props) => {
  const { row, isSelected } = props;
  return (
    <tr
      {...row.getRowProps()}
      sx={{
        height: 32,
        backgroundColor: isSelected ? "lightGreen" : "transparent",
        "&:hover": {
          backgroundColor: isSelected ? "lightGreen" : "beige",
        },
      }}
    >
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            sx={{
              textTransform: cell.column.capitalize ? "capitalize" : "initial",
            }}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div>
        <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          sx={{ fontSize: 30 }}
        />
      </div>
    );
  }
);

const Delete = ({ selectedFlatRows, token, fetchCellar, closeModal }) => {
  const [loading, setLoading] = useState(false);

  const deleteItems = () => {
    setLoading(true);

    let promises = [];
    selectedFlatRows.forEach((row) => {
      promises.push(
        axios({
          method: "DELETE",
          url: `${baseUrl}/api/admin/vault/${row.original.uuid}/`,
          headers: {
            Authorization: "Token " + token,
          },
        })
      );
    });

    Promise.all([promises]).then((responses) => {
      setLoading(false);
      closeModal();
      fetchCellar();
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        deleteItems();
      }}
    >
      <div>
        Are you sure you want to delete {selectedFlatRows.length}{" "}
        {selectedFlatRows.length === 1 ? "item" : "items"}? This will
        permanently delete the{" "}
        {selectedFlatRows.length === 1 ? "entry" : "entries"} from the cellar.
      </div>
      <Button
        sx={{
          variant: "primary",
          px: 40,
          mt: 30,
          width: "100%",
          borderRadius: 100,
        }}
        type="submit"
      >
        {loading ? "Deleting..." : "Delete"}
      </Button>
    </form>
  );
};

const Edit = (props) => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { quantity, variant } = props.editingRow;
    console.log("Edit", props.editingRow);

    setInputs({
      size: variant.size,
      name: variant.name ? variant.name : "",
      quantity: quantity,
    });
  }, [props]);

  const save = () => {
    const { uuid } = props.editingRow;
    const { token, closeModal, fetchCellar } = props;
    setLoading(true);

    axios({
      method: "POST",
      url: `${baseUrl}/api/admin/vault/${uuid}/`,
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        size: inputs.size,
        quantity: inputs.quantity,
        name: inputs.name,
      },
    })
      .then((response) => {
        setLoading(false);
        closeModal();
        fetchCellar();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
        sx={{ position: "relative" }}
      >
        <div sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
          <div>
            <Label htmlFor="size">Size</Label>
            <Input
              name="size"
              type="number"
              placeholder="Enter size in ml"
              required
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  size: parseInt(target.value),
                }))
              }
              value={inputs.size}
            />
          </div>
          <div>
            <Label htmlFor="quantity">Quantity</Label>
            <Input
              name="quantity"
              min="1"
              placeholder="Enter quantity"
              type="number"
              required
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  quantity: target.value,
                }))
              }
              value={inputs.quantity}
            />
          </div>
          <div>
            <Label htmlFor="name">Location</Label>
            <Input
              name="name"
              min="1"
              placeholder="Enter location"
              type="text"
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  name: target.value,
                }))
              }
              value={inputs.name}
            />
          </div>
        </div>
        <div sx={{ dispaly: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              variant: "primary",
              px: 40,
              mt: 30,
              width: "100%",
              borderRadius: 100,
            }}
            type="submit"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>
      </form>
    </div>
  );
};

function formatSize(size) {
  return `${size}ml`;
  // return `${size / 10} cl`;
}

function ModalScreen({ children }) {
  return (
    <div
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: "pastel",
        overflow: "scroll",
        lineHeight: "40px",
      }}
    >
      {children}
    </div>
  );
}

const EditBottle = (props) => {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { country, region, appellation, domain, cuvee } =
      props.editingRow.bottle;

    console.log("Edit Bottle", props.editingRow);

    setInputs({
      country: country,
      region: region,
      appellation: appellation,
      domain: domain,
      cuvee: cuvee,
    });
  }, [props]);

  const save = () => {
    const { uuid } = props.editingRow.bottle;
    const { country, region, appellation, domain, cuvee } = inputs;
    const { token, closeModal, fetchCellar } = props;
    setLoading(true);

    axios({
      method: "POST",
      url: `${baseUrl}/api/admin/bottles/${uuid}/`,
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        country: country,
        region: region,
        designation: appellation,
        producer: domain,
        cuvee: cuvee,
      },
    })
      .then((response) => {
        setLoading(false);
        closeModal();
        fetchCellar();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
        sx={{ position: "relative" }}
      >
        <div sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
          <div>
            <Label htmlFor="country">Country</Label>
            <Input
              name="country"
              type="text"
              placeholder="Country"
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  country: target.value,
                }))
              }
              value={inputs.country}
            />
          </div>
          <div>
            <Label htmlFor="region">Region</Label>
            <Input
              name="region"
              placeholder="Region"
              type="text"
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  region: target.value,
                }))
              }
              value={inputs.region}
            />
          </div>
          <div>
            <Label htmlFor="appellation">Designation</Label>
            <Input
              name="appellation"
              placeholder="Designation"
              type="text"
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  appellation: target.value,
                }))
              }
              value={inputs.appellation}
            />
          </div>
          <div>
            <Label htmlFor="domain">Producer</Label>
            <Input
              name="domain"
              placeholder="Producer"
              type="text"
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  domain: target.value,
                }))
              }
              value={inputs.domain}
            />
          </div>
          <div>
            <Label htmlFor="cuvee">Cuvée</Label>
            <Input
              name="cuvee"
              placeholder="Cuvee"
              type="text"
              onChange={({ target }) =>
                setInputs((state) => ({
                  ...state,
                  cuvee: target.value,
                }))
              }
              value={inputs.cuvee}
            />
          </div>
        </div>
        <div sx={{ dispaly: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              variant: "primary",
              px: 40,
              mt: 30,
              width: "100%",
              borderRadius: 100,
            }}
            type="submit"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>
      </form>
    </div>
  );
};
