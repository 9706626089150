/** @jsxRuntime classic */
/** @jsx jsx */
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { jsx } from "theme-ui";

import Cellar from "./Cellar";
import Header from "./Header";
import { baseUrl } from "../utils/constants";
import { retrieveAuth } from "../App";

export default function AdminCellar() {
  const { uuid } = useParams();
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState({});
  const [cellarOwner, setCellarOwner] = useState({});
  const [loading, setLoading] = useState(true);
  const [deposits, setDeposits] = useState([]);
  const url = baseUrl + `/api/admin/user/${uuid}/deposits/`;

  const fetchCellar = () => {
    const auth = retrieveAuth();
    setUser(auth.user);
    setAuth(auth.token);
    const { token } = auth.token;

    axios({
      method: "get",
      url: url,
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((response) => {
        console.log("Deposits:", response);
        setLoading(false);
        setDeposits(response.data.results);
        setCellarOwner(response.data.metadata.user);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchCellar();
  }, []);

  return (
    <div
      sx={{
        minHeight: "100vh",
      }}
    >
      <Header user={user} isAdmin />

      <div
        sx={{
          px: [10, 50],
          pb: [10, 50],
        }}
      >
        {!loading ? (
          <Cellar
            deposits={deposits}
            token={auth.token}
            user={user}
            isAdmin={true}
            cellarOwner={cellarOwner}
            uuid={uuid}
            fetchCellar={fetchCellar}
          />
        ) : (
          <div
            sx={{
              variant: "components.flexCenter",
              width: "100%",
              height: "calc(100vh - 140px)",
            }}
          >
            <span>
              <div className="loader"></div>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
