/** @jsxRuntime classic */
/** @jsx jsx */
import axios from "axios";
import React, { useState, memo } from "react";
import { jsx, Button, Textarea } from "theme-ui";
import ArrowLeft from "../icons/ArrowLeft";
import { useTable } from "react-table";
import Calendar from "react-calendar";
import * as NumericInput from "react-numeric-input";

import { baseUrl } from "../utils/constants";
import Heading from "./Heading";
import "./Calendar.css";
import "react-calendar/dist/Calendar.css";

export default function WithdrawFlow({
  setWithdrawalFlow,
  selectedFlatRows,
  token,
  cellarOwner,
  isAdmin,
}) {
  const originalRows = [...selectedFlatRows.map((d) => d.original)];
  const [bottles, setBottles] = useState(
    originalRows.map((row) => {
      return { ...row, quantity: 1 };
    })
  );
  const [screen, setScreen] = useState("DEFAULT");
  const [previousScreen, setPreviousScreen] = useState("");
  const [widthdrawType, setWidthdrawType] = useState(false);

  const today = new Date();
  const now = new Date();

  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const closeTime = new Date();
  closeTime.setHours(12);
  closeTime.setMinutes(0);

  const monday = new Date();
  monday.setDate(monday.getDate() + ((1 + 7 - monday.getDay()) % 7));

  let minDate = tomorrow;
  let cutoffTime = false;

  if (today.getDay() >= 5) {
    if (now.getTime() >= closeTime.getTime()) {
      minDate = monday;
      cutoffTime = true;
    }
  }

  const [date, setDate] = useState(minDate);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  function changeQuantity(value, uuid) {
    const index = bottles.findIndex((element) => element.uuid === uuid);
    let newArray = [...bottles];
    newArray[index] = {
      ...newArray[index],
      quantity: parseInt(value),
    };
    setBottles(newArray);
  }

  function Table(props) {
    const { bottles, displayOnly } = props;

    const columns = React.useMemo(
      () => [
        {
          Header: "Type",
          accessor: "bottle.colour",
          columnWidth: 70,
        },
        {
          Header: "Country",
          accessor: "bottle.country",
        },
        {
          Header: "Region",
          accessor: "bottle.region",
        },
        {
          Header: "Designation",
          accessor: "bottle.appellation",
          sortType: "basic",
        },
        {
          Header: "Producer",
          accessor: "bottle.domain",
          sortType: "basic",
        },

        {
          Header: "Cuvée",
          accessor: "bottle.cuvee",
        },
        {
          Header: "Vintage",
          accessor: "variant.vintage",
          sortType: "basic",
          columnWidth: 100,
          Cell: (props) => {
            const { value } = props.cell;
            return <div>{value === "0" ? "NV" : value}</div>;
          },
        },
        {
          Header: "Size",
          accessor: "variant.size",
          sortType: "basic",
          columnWidth: 100,
          Cell: (props) => {
            return <div>{formatSize(props.cell.value)}</div>;
          },
        },
        {
          Header: "Quantity",
          accessor: "quantity",
          sortType: "basic",
          columnWidth: 100,
          Cell: (props) => {
            return (
              <div>
                {displayOnly ? (
                  <div>{props.cell.value}</div>
                ) : (
                  <NumericInput
                    min={1}
                    value={props.cell.value}
                    max={originalRows[props.row.index].quantity}
                    style={{
                      input: {
                        background: "none",
                        border: "none",
                        textAlign: "center",
                        width: 60,
                        borderWidth: 0,
                        paddingRight: 20,
                        paddingTop: 5,
                        paddingBottom: 5,
                      },
                    }}
                    onChange={(e) =>
                      changeQuantity(e, props.cell.row.original.uuid)
                    }
                  />
                )}
              </div>
            );
          },
        },
      ],
      [displayOnly]
    );

    const data = bottles;

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        data,
        columns,
      });

    return (
      <div {...props}>
        <table
          {...getTableProps()}
          style={{
            width: "100%",
            fontSize: 16,
            marginBottom: 30,
            borderCollapse: "collapse",
          }}
        >
          <thead
            sx={{
              backgroundColor: "black",
              color: "white",
              lineHeight: "30px",
              height: 30,
              position: "sticky",
              top: 0,
              zIndex: 200,
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    sx={{
                      width: column.columnWidth
                        ? `${column.columnWidth}px`
                        : "auto",
                    }}
                    className="table-header"
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} sx={{ textAlign: "center" }}>
            {rows.map((row, i) => {
              prepareRow(row);
              return <MemoizedRow row={row} />;
            })}
          </tbody>
        </table>
      </div>
    );
  }

  const submitRequest = (e) => {
    setLoading(true);
    let request = [];
    bottles.forEach((item) => {
      request.push({
        bottle_uuid: item.bottle.uuid,
        quantity: item.quantity,
        variant_uuid: item.variant.uuid,
      });
    });

    let payload = {
      request,
      fulfilment_type: widthdrawType,
      note: note,
      requested_for: date,
    };

    if (isAdmin && cellarOwner) {
      payload.for_user_uuid = cellarOwner.uuid;
    }

    axios({
      method: "POST",
      url: baseUrl + "/api/requests/",
      headers: {
        Authorization: "Token " + token,
      },
      data: payload,
    })
      .then((response) => {
        setLoading(false);
        setScreen("CONFIRM");
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        alert("backend error but continuing anyway");
        setScreen("CONFIRM");
      });
  };

  const MemoizedRow = memo((props) => {
    const { row } = props;
    return (
      <tr
        {...row.getRowProps()}
        sx={{
          height: 32,
        }}
      >
        {row.cells.map((cell) => {
          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
        })}
      </tr>
    );
  });

  const displayDate = date.toLocaleString("en-US", {
    month: "long",
    day: "2-digit",
    year: "numeric",
  });

  const renderTileContent = ({ date }) => {
    return cutoffTime &&
      today.getDate() <= date.getDate() &&
      date.getDate() < minDate.getDate() ? (
      <div
        sx={{
          position: "absolute",
          inset: 0,
          ".tooltip": {
            opacity: 0,
            visibility: "hidden",
          },
          "&:hover": {
            ".tooltip": {
              opacity: 1,
              visibility: "visible",
            },
          },
        }}
      >
        <div
          sx={{
            background: "black",
            position: "absolute",
            bottom: "100%",
            padding: "8px 10px",
            borderRadius: 4,
            color: "white",
            right: 0,
            fontSize: 12,
            textAlign: "left",
            width: 230,
            a: {
              color: "white",
            },
          }}
          className="tooltip"
        >
          You have missed the cut-off time to request a bottle for that date,
          please email{" "}
          <a href="mailto:members@planque.co.uk">members@planque.co.uk</a> and
          we will do our best to accommodate if possible.
        </div>
      </div>
    ) : null;
  };

  function renderSwitch(screen) {
    switch (screen) {
      case "DEFAULT":
        return (
          <Centered>
            <Card>
              <Inner
                onClick={() => {
                  setScreen("DRINK_AT_PLANQUE");
                  setWidthdrawType("Drink-In");
                }}
              >
                Drink at Planque
              </Inner>
            </Card>

            <Card>
              <Inner
                onClick={() => {
                  setScreen("COLLECT_AT_PLANQUE");
                  setWidthdrawType("Collection");
                }}
              >
                Collect at Planque
              </Inner>
            </Card>

            <Card>
              <Inner
                onClick={() => {
                  setScreen("DELIVERY");
                  setWidthdrawType("Delivery");
                }}
              >
                Delivery
              </Inner>
            </Card>

            <BackButton
              styles={{ position: "absolute", top: 20, left: 20 }}
              onClick={(e) => setWithdrawalFlow(false)}
            />
          </Centered>
        );
      case "DRINK_AT_PLANQUE":
        return (
          <Wrapper>
            <div sx={{ display: "flex", flexWrap: "wrap" }}>
              <div sx={{ width: "calc(100% - 400px)", pr: 50 }}>
                <div sx={{ display: "flex" }}>
                  <BackButton
                    onClick={(e) => setScreen("DEFAULT")}
                    styles={{ mr: 20 }}
                  />
                  <Heading sx={{ mt: "4px" }}>Drink at Planque</Heading>
                </div>
                You are requesting the following bottles to widthdraw to drink
                on site at Planque
                <div sx={{ mt: 30 }}>
                  <Table bottles={bottles} />
                </div>
              </div>
              <div
                sx={{ width: 400, position: "sticky", top: 10, height: "100%" }}
              >
                <Heading>Select date</Heading>
                Enter the date of your reservation at Planque:
                <div
                  sx={{
                    mb: 30,
                    mt: 10,
                    borderRadius: 10,
                    overflow: "hidden",
                    backgroundColor: "white",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Calendar
                    onChange={setDate}
                    value={date}
                    minDate={minDate}
                    tileContent={renderTileContent}
                  />
                </div>
                <div sx={{ mt: 30 }}>
                  <Button
                    sx={{
                      variant: "primary",
                      px: 40,
                      py: 0,
                      width: "100%",
                      borderRadius: 100,
                    }}
                    onClick={(e) => {
                      setPreviousScreen("DRINK_AT_PLANQUE");
                      setScreen("SUMMARY");
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </Wrapper>
        );
      case "COLLECT_AT_PLANQUE":
        return (
          <Wrapper>
            <div sx={{ display: "flex", flexWrap: "wrap" }}>
              <div sx={{ width: "calc(100% - 400px)", pr: 50 }}>
                <div sx={{ display: "flex" }}>
                  <BackButton
                    onClick={(e) => setScreen("DEFAULT")}
                    styles={{ mr: 20 }}
                  />
                  <Heading sx={{ mt: "4px" }}>Collect at Planque</Heading>
                </div>
                You are requesting the following bottles for collection at on
                site at Planque
                <div sx={{ mt: 30 }}>
                  <Table bottles={bottles} />
                </div>
              </div>
              <div
                sx={{ width: 400, position: "sticky", top: 10, height: "100%" }}
              >
                <Heading>Select date</Heading>
                Enter the date you wish to collect on
                <div
                  sx={{
                    mb: 30,
                    mt: 10,
                    borderRadius: 10,
                    overflow: "hidden",
                    backgroundColor: "white",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Calendar
                    onChange={setDate}
                    value={date}
                    minDate={minDate}
                    tileContent={renderTileContent}
                  />
                </div>
                <div sx={{ mt: 30 }}>
                  <Button
                    sx={{
                      variant: "primary",
                      px: 40,
                      py: 0,
                      width: "100%",
                      borderRadius: 100,
                    }}
                    onClick={(e) => {
                      setPreviousScreen("COLLECT_AT_PLANQUE");
                      setScreen("SUMMARY");
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </Wrapper>
        );
      case "DELIVERY":
        return (
          <Wrapper>
            <div sx={{ display: "flex", flexWrap: "wrap" }}>
              <div sx={{ width: "calc(100% - 400px)", pr: 50 }}>
                <div sx={{ display: "flex" }}>
                  <BackButton
                    onClick={(e) => setScreen("DEFAULT")}
                    styles={{ mr: 20 }}
                  />
                  <Heading sx={{ mt: "4px" }}>Delivery</Heading>
                </div>
                You are requesting the following bottles for delivery from
                Planque
                <div sx={{ mt: 30 }}>
                  <Table bottles={bottles} />
                </div>
              </div>
              <div
                sx={{ width: 400, position: "sticky", top: 10, height: "100%" }}
              >
                <Heading>Select date</Heading>
                <div
                  sx={{
                    mb: 30,
                    mt: 10,
                    borderRadius: 10,
                    overflow: "hidden",
                    backgroundColor: "white",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Calendar
                    onChange={setDate}
                    value={date}
                    minDate={minDate}
                    tileContent={renderTileContent}
                  />
                </div>

                {/* <Heading>Select address</Heading>
                <div sx={{ mb: 30, mt: 10 }}>
                  <div
                    sx={{
                      width: "100%",
                      height: 200,
                      borderRadius: 10,
                      background: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div>todo</div>
                  </div>
                </div> */}
                <div sx={{ mt: 30 }}>
                  <Button
                    sx={{
                      variant: "primary",
                      px: 40,
                      py: 0,
                      width: "100%",
                      borderRadius: 100,
                    }}
                    onClick={(e) => {
                      setPreviousScreen("DELIVERY");
                      setScreen("SUMMARY");
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </Wrapper>
        );
      case "SUMMARY":
        return (
          <Centered>
            <BackButton
              styles={{ position: "absolute", top: 20, left: 20 }}
              onClick={(e) => setScreen(previousScreen)}
            />
            <div sx={{ width: "90%", maxWidth: 1440 }}>
              <div sx={{ display: "flex" }}>
                <Heading fontSize={[48, 66]}>Summary</Heading>
              </div>
              <p>
                You are requesting {getBottleCount(bottles)} bottles for{" "}
                {widthdrawType} on {displayDate}.
              </p>
              <div sx={{ my: 30, maxHeight: 500, overflow: "scroll" }}>
                <Table bottles={bottles} displayOnly />
              </div>

              <div sx={{ maxWidth: 500, ml: "auto" }}>
                <Heading>Add note</Heading>
                <div>
                  <Textarea
                    sx={{ mt: 10 }}
                    value={note}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    placeholder="Add special instructions"
                  ></Textarea>
                </div>
                <Button
                  sx={{
                    variant: "primary",
                    px: 40,
                    py: 0,
                    width: "100%",
                    borderRadius: 100,
                    mt: 30,
                  }}
                  onClick={(e) => {
                    submitRequest(e);
                  }}
                >
                  {loading ? "Submitting..." : "Confirm selection"}
                </Button>
              </div>
            </div>
          </Centered>
        );
      case "CONFIRM":
        return (
          <Centered>
            <div sx={{ width: "90%", maxWidth: 1440 }}>
              <Heading fontSize={[48, 66]}>Thank you!</Heading>
              <p>
                We have received your {widthdrawType} request for{" "}
                {getBottleCount(bottles)} bottles on {displayDate}.
              </p>
              <div sx={{ my: 30, maxHeight: 500, overflow: "scroll" }}>
                <Table bottles={bottles} displayOnly />
              </div>
              Please check your email for a confirmation.
              <div>
                <Button
                  sx={{
                    variant: "primary",
                    px: 40,
                    py: 0,
                    width: "100%",
                    borderRadius: 100,
                    mt: 30,
                    maxWidth: 300,
                  }}
                  onClick={(e) => {
                    window.location.reload();
                  }}
                >
                  Back to Cellar
                </Button>
              </div>
            </div>
          </Centered>
        );
      default:
        return "foo";
    }
  }

  return <div sx={{ width: "100%" }}>{renderSwitch(screen)}</div>;
}

function getBottleCount(bottles) {
  let count = 0;
  bottles.forEach((bottle) => {
    count = count + bottle.quantity;
  });
  return count;
}

function Card({ children, props }) {
  return (
    <div
      sx={{
        position: "relative",
        cursor: "pointer",
        width: 400,
        borderRadius: 10,
        mx: 10,
        // backgroundColor: "red",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        // boxShadow: "0 0 20px 0 rgba(0,0,0,0.2)",
        fontSize: 44,
        fontFamily: "heading",
        textAlign: "center",
        transition: "all 100ms ease-in-out",
        "::before": {
          paddingTop: "120%",
          display: "block",
          content: "''",
        },
        "&:hover": {
          transform: "translateY(-3px)",
          backgroundColor: "beige",
        },
      }}
      {...props}
    >
      {children}
    </div>
  );
}

function formatSize(size) {
  return `${size / 10} cl`;
}

function Inner(props) {
  const { children } = props;
  return (
    <div
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        variant: "components.flexCenter",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

function BackButton(props) {
  const { styles = {} } = props;
  return (
    <Button
      {...props}
      sx={{
        background: "transparent",
        borderRadius: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontSize: 22,
        p: 0,
        width: 40,
        height: 40,
        ...styles,
      }}
    >
      <ArrowLeft fill="black" />
    </Button>
  );
}

function Wrapper(props) {
  const { children } = props;
  return (
    <div
      sx={{
        width: "90%",
        mt: 50,
        mx: "auto",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

function Centered(props) {
  const { children } = props;
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
      {...props}
    >
      {children}
    </div>
  );
}
