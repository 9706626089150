/** @jsxRuntime classic */
/** @jsx jsx */
import axios from "axios";
import { useState, useEffect } from "react";
import { jsx, Heading } from "theme-ui";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Cellar from "./Cellar";
import Header from "./Header";
import RequestsTable from "./RequestsTable";
import { baseUrl } from "../utils/constants";
import { retrieveAuth } from "../App";

export default function Dashboard() {
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [deposits, setDeposits] = useState([]);
  const [requests, setRequests] = useState([]);
  const [historicalRequests, setHistoricalRequests] = useState([]);
  const [cellarOwner, setCellarOwner] = useState({});

  useEffect(() => {
    const auth = retrieveAuth();
    setUser(auth.user);
    setAuth(auth.token);
    console.log("Dashboard", user, auth);
    const { token } = auth.token;

    const depositRequest = axios({
      method: "get",
      url: baseUrl + "/api/deposits/",
      headers: {
        Authorization: "Token " + token,
      },
    });

    const requestsRequest = axios({
      method: "get",
      url: baseUrl + "/api/requests/",
      headers: {
        Authorization: "Token " + token,
      },
    });

    const historicalRequests = axios({
      method: "get",
      url: baseUrl + "/api/requests/?selection=historical",
      headers: {
        Authorization: "Token " + token,
      },
    });

    Promise.all([depositRequest, requestsRequest, historicalRequests]).then(
      (responses) => {
        setDeposits(responses[0].data.results);
        setCellarOwner(responses[0].data.metadata.user);
        setRequests(responses[1].data);
        setHistoricalRequests(responses[2].data);
        setLoaded(true);
        console.log("Deposits", responses[0]);
        console.log("Active", responses[1].data);
        console.log("History", responses[2].data);
      }
    );
  }, []);

  return (
    <div
      sx={{
        minHeight: "100vh",
      }}
    >
      <Header user={user} />

      <div
        sx={{
          px: [10, 50],
          pb: [10, 50],
        }}
      >
        {!loaded ? (
          <div
            sx={{
              variant: "components.flexCenter",
              width: "100%",
              height: 500,
            }}
          >
            <span>
              <div className="loader"></div>
            </span>
          </div>
        ) : (
          <div>
            <Tabs>
              <TabList>
                <Tab key="cellar-tab">
                  Cellar {deposits.length > 0 && `(${deposits.length})`}
                </Tab>
                <Tab key="requests-tab">
                  Requests {requests.length > 0 && `(${requests.length})`}
                </Tab>
              </TabList>

              <TabPanel key="cellar">
                {deposits.length ? (
                  <Cellar
                    deposits={deposits}
                    token={auth.token}
                    user={user}
                    cellarOwner={cellarOwner}
                  />
                ) : (
                  <div
                    sx={{
                      variant: "components.flexCenter",
                      width: "100%",
                      height: "calc(100vh - 260px)",
                    }}
                  >
                    <Heading fontSize={[48, 66]}>your cellar is empty</Heading>
                  </div>
                )}
              </TabPanel>
              <TabPanel key="requests">
                <div sx={{ mt: 30 }}>
                  <Heading sx={{ mb: 2 }}>Active Requests</Heading>
                  {requests.length > 0 ? (
                    <RequestsTable data={requests} />
                  ) : (
                    <Placeholder>
                      <div sx={{ opacity: 0.2 }}>no active requests</div>
                    </Placeholder>
                  )}
                </div>

                <div sx={{ mt: 30 }}>
                  <Heading sx={{ mb: 2 }}>History</Heading>
                  {historicalRequests.length > 0 ? (
                    <RequestsTable data={historicalRequests} />
                  ) : (
                    <Placeholder>
                      <div sx={{ opacity: 0.2 }}>no historical requests</div>
                    </Placeholder>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}

const Placeholder = ({ children }) => {
  return (
    <div
      sx={{
        width: "100%",
        padding: 100,
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
};
